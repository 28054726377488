import { Button, Carousel } from 'antd';
import { Input, withButtonStyle } from 'boatjump-react-components';
import { Link } from 'gatsby';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header/Header';
import '../components/layout.css';
import SEO from '../components/seo';
import '../static/landing.css';

const imageArray = [1, 2, 3, 4, 5];

const ButtonWithStyle = withButtonStyle(Button);

const IndexPage = ({ pageContext }) => {
  const [t, i18n] = useTranslation();
  const {
    url, description, styles = [], customPost = '<div></div>', suggestedDestinations, lang = 'es', slugPartBoatRent, special, kinds, places,
  } = pageContext;
  const carousel = useRef(null);
  const destinationsThatAreNotBases = JSON.parse(places).filter((destination) => destination.type !== 'base');
  const [carouselImgs, setCarouselImgs] = useState(imageArray);
  const [headerBackground, setHeaderBackground] = useState({ background: 'none' });
  const [place, setPlace] = useState([]);
  const [duration, setDuration] = useState('');
  const [startDate, setStartDate] = useState('');
  const [boat, setBoat] = useState([]);
  moment.locale('es');
  const queryParameters = `?${duration ? `duration=${duration}` : 'duration=7'}&${startDate ? `start_date=${startDate}` : `start_date=${moment().locale('es').format('L')}`}${boat ? `&boat=${boat}` : ''}`;

  const handleScroll = (event) => {
    event.preventDefault();
    const { scrollTop } = event.target.scrollingElement;
    const { offsetHeight: carouselHeigth } =
      carousel.current ? carousel.current : { offsetHeight: 0 };
    const headerHeigth = 64;

    if (scrollTop > (carouselHeigth - headerHeigth)) {
      setHeaderBackground({ background: 'var(--secondary-color)' });
    } else {
      setHeaderBackground({ background: 'none' });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const tmpArray = imageArray.map((el) => renderToString(<div
      className="FakeLanding"
      key={el}
      style={{
        background: `linear-gradient(0deg,rgba(0,0,0,0.2),rgba(0,0,0,0.2)), url("http://assets.boatjump.com/static/images/home/backgrounds/home-background-${el}.jpg")no-repeat center`,
        backgroundSize: 'cover',
      }}
    />));
    setCarouselImgs(tmpArray);
  }, []);

  const valueHandlerPlace = (e, value) => {
    setPlace(value);
  };

  const valueHandlerDuration = (e, value) => {
    setDuration(value);
  };

  const valueHandlerDeparture = (e, value) => {
    setStartDate(value);
  };

  const valueHandlerBoat = (e, value) => {
    setBoat(value);
  };


  return (
    <>
      <SEO title="Home" description={description} url={url} />
      <Header style={headerBackground} special={special} />
      <div ref={carousel}>
        <Carousel style={{ zIndex: '0' }} effect="fade" dots="false" autoplay>
          {
            carouselImgs.map((el) => <div key={el} dangerouslySetInnerHTML={{ __html: el }} />)
          }
        </Carousel>
      </div>
      <div style={{
        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start',
      }}
      >
        <div className="LandingContainer">
          <div className="LandingFormContainer">
            <div className="LandingFormContainerTitleContainer">Encuentra tu barco entre más de 429 destinos</div>
            <div className="LandingFormContainerFormContainer">
              <div className="LandingFormContainerFormContainerInput">
                <Input value={place} currentValue={valueHandlerPlace} label="¿A dónde vamos?" placeholder="Islas Baleares, Ibiza, Mallorca..." icon="Ubication">
                  {
                    destinationsThatAreNotBases.map((destination) => {
                      return { key: destination.slug[lang], value: destination.name[lang] };
                    })
                  }
                </Input>
              </div>
              <div className="LandingFormContainerFormContainerDatesContainer">
                <div className="LandingFormContainerFormContainerInput">
                  <Input value={duration} currentValue={valueHandlerDuration} label="Duración" icon="Calendar" placeholder="dd/mm/aaaa" />
                </div>
                <div className="LandingFormContainerFormContainerInput">
                  <Input value={startDate} currentValue={valueHandlerDeparture} label="Salida" icon="Calendar" placeholder="dd/mm/aaaa" />
                </div>
              </div>
              <div className="LandingFormContainerFormContainerInput">
                <Input value={boat} currentValue={valueHandlerBoat} label="Selecciona el tipo de barco" placeholder="Catamarán, Yate, Lancha...">
                  {
                    kinds.map((kind) => {
                      return { key: kind.slug[lang], value: kind.name[lang] };
                    })
                  }
                </Input>
              </div>
              <div className="LandingFormContainerFormContainerButton">
                <Link to={`/${lang}/${slugPartBoatRent[lang]}/${place || `${suggestedDestinations['1'].slug[lang]}`}/${queryParameters}`}>
                  <ButtonWithStyle>Buscar</ButtonWithStyle>
                </Link>
              </div>
            </div>
          </div>
          <h1 className="LandingExploreTitle">
            {t('Explorar destinos')}
          </h1>
          <div className="LandingDummyContainers">
            {/* Islas Baleares */}
            <div className="LandingDummyContainer">
              <div className="LandingDummyContainerInfo">
                <div className="LandingDummyContainerInfoContainer">
                  <h2 style={{ marginBottom: '0%' }} className="LandingDummyContainerInfoContainerTitle"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['1510'].slug[lang]}`}>{suggestedDestinations['1510'].name.es ? suggestedDestinations['1510'].name.es : suggestedDestinations['1510'].name.en}</Link></h2>
                  {suggestedDestinations['1510'].name.es}
                </div>
                <div className="LandingDummyContainerInfoPlacesWithInfo">
                  <div className="LandingDummyContainerInfoPlacesWithInfoPlace"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['118'].slug[lang]}`}>{suggestedDestinations['118'].name.es ? suggestedDestinations['118'].name.es : suggestedDestinations['118'].name.en}</Link></div>
                  <div className="LandingDummyContainerInfoPlacesWithInfoInfo">
                    <div style={{ marginRight: '8px' }}>
                          {suggestedDestinations['118'].boats_count_with_child_places}
                                &nbsp;
barcos
                        </div>
                    <div>
                          {100}
                                &nbsp;
puertos
                        </div>
                  </div>
                </div>
                <div className="LandingDummyContainerInfoPlacesWithInfo">
                  <div className="LandingDummyContainerInfoPlacesWithInfoPlace"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['119'].slug[lang]}`}>{suggestedDestinations['119'].name.es ? suggestedDestinations['119'].name.es : suggestedDestinations['119'].name.en}</Link></div>
                  <div className="LandingDummyContainerInfoPlacesWithInfoInfo">
                    <div style={{ marginRight: '8px' }}>
                          {suggestedDestinations['119'].boats_count_with_child_places}
                                &nbsp;
barcos
                        </div>
                    <div>
                          {100}
                                &nbsp;
puertos
                        </div>
                  </div>
                </div>
                <div className="LandingDummyContainerInfoPlacesWithInfo">
                  <div className="LandingDummyContainerInfoPlacesWithInfoPlace"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['120'].slug[lang]}`}>{suggestedDestinations['120'].name.es ? suggestedDestinations['120'].name.es : suggestedDestinations['120'].name.en}</Link></div>
                  <div className="LandingDummyContainerInfoPlacesWithInfoInfo">
                    <div style={{ marginRight: '8px' }}>
                          {suggestedDestinations['120'].boats_count_with_child_places}
                                &nbsp;
barcos
                        </div>
                    <div>
                          {100}
                                &nbsp;
puertos
                        </div>
                  </div>
                </div>
              </div>
              <div
                className="LandingDummyContainerImage"
                key="a"
                style={{
                  background: 'linear-gradient(0deg,rgba(0,0,0,0),rgba(0,0,0,0)), url("http://assets.boatjump.com/static/images/home/backgrounds/home-background-1.jpg")no-repeat center',
                  backgroundSize: 'cover',
                }}
              />
            </div>

            <div className="LandingLineContainerLine" />

            {/* Caribe */}

            <div className="LandingDummyContainer">
              <div className="LandingDummyContainerInfo">
                <div className="LandingDummyContainerInfoContainer">
                  <h2 style={{ marginBottom: '0%' }} className="LandingDummyContainerInfoContainerTitle"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['1139'].slug[lang]}`}>{suggestedDestinations['1139'].name.es ? suggestedDestinations['1139'].name.es : suggestedDestinations['1139'].name.en}</Link></h2>
                  {suggestedDestinations['1139'].name.es}
                </div>
                <div className="LandingDummyContainerInfoPlacesWithInfo">
                  <div className="LandingDummyContainerInfoPlacesWithInfoPlace"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['289'].slug[lang]}`}>{suggestedDestinations['289'].name.es ? suggestedDestinations['289'].name.es : suggestedDestinations['289'].name.en}</Link></div>
                  <div className="LandingDummyContainerInfoPlacesWithInfoInfo">
                    <div style={{ marginRight: '8px' }}>
                      {suggestedDestinations['289'].boats_count_with_child_places}
                                &nbsp;
barcos
                    </div>
                    <div>
                      {100}
                                &nbsp;
puertos
                    </div>
                  </div>
                </div>
                <div className="LandingDummyContainerInfoPlacesWithInfo">
                  <div className="LandingDummyContainerInfoPlacesWithInfoPlace"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['1507'].slug[lang]}`}>{suggestedDestinations['1507'].name.es ? suggestedDestinations['1507'].name.es : suggestedDestinations['1507'].name.en}</Link></div>
                  <div className="LandingDummyContainerInfoPlacesWithInfoInfo">
                    <div style={{ marginRight: '8px' }}>
                      {suggestedDestinations['1507'].boats_count_with_child_places}
                                &nbsp;
barcos
                    </div>
                    <div>
                      {100}
                                &nbsp;
puertos
                    </div>
                  </div>
                </div>
                <div className="LandingDummyContainerInfoPlacesWithInfo">
                  <div className="LandingDummyContainerInfoPlacesWithInfoPlace"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['1329'].slug[lang]}`}>{suggestedDestinations['1329'].name.es ? suggestedDestinations['1329'].name.es : suggestedDestinations['1329'].name.en}</Link></div>
                  <div className="LandingDummyContainerInfoPlacesWithInfoInfo">
                    <div style={{ marginRight: '8px' }}>
                      {suggestedDestinations['1329'].boats_count_with_child_places}
                                &nbsp;
barcos
                    </div>
                    <div>
                      {100}
                                &nbsp;
puertos
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="LandingDummyContainerImage"
                key="a"
                style={{
                  background: 'linear-gradient(0deg,rgba(0,0,0,0),rgba(0,0,0,0)), url("http://assets.boatjump.com/static/images/home/backgrounds/home-background-1.jpg")no-repeat center',
                  backgroundSize: 'cover',
                }}
              />
            </div>

            <div className="LandingLineContainerLine" />

            {/* Croacia */}

            <div className="LandingDummyContainer">
              <div className="LandingDummyContainerInfo">
                <div className="LandingDummyContainerInfoContainer">
                  <h2 style={{ marginBottom: '0%' }} className="LandingDummyContainerInfoContainerTitle"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['1165'].slug[lang]}`}>{suggestedDestinations['1165'].name.es ? suggestedDestinations['1165'].name.es : suggestedDestinations['1165'].name.en}</Link></h2>
                  {suggestedDestinations['1165'].name.es}
                </div>
                <div className="LandingDummyContainerInfoPlacesWithInfo">
                  <div className="LandingDummyContainerInfoPlacesWithInfoPlace"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['1229'].slug[lang]}`}>{suggestedDestinations['1229'].name.es ? suggestedDestinations['1229'].name.es : suggestedDestinations['1229'].name.en}</Link></div>
                  <div className="LandingDummyContainerInfoPlacesWithInfoInfo">
                    <div style={{ marginRight: '8px' }}>
                      {suggestedDestinations['1229'].boats_count_with_child_places}
                                &nbsp;
barcos
                    </div>
                    <div>
                      {100}
                                &nbsp;
puertos
                    </div>
                  </div>
                </div>
                <div className="LandingDummyContainerInfoPlacesWithInfo">
                  <div className="LandingDummyContainerInfoPlacesWithInfoPlace"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['1223'].slug[lang]}`}>{suggestedDestinations['1223'].name.es ? suggestedDestinations['1223'].name.es : suggestedDestinations['1223'].name.en}</Link></div>
                  <div className="LandingDummyContainerInfoPlacesWithInfoInfo">
                    <div style={{ marginRight: '8px' }}>
                      {suggestedDestinations['1223'].boats_count_with_child_places}
                                &nbsp;
barcos
                    </div>
                    <div>
                      {100}
                                &nbsp;
puertos
                    </div>
                  </div>
                </div>
                <div className="LandingDummyContainerInfoPlacesWithInfo">
                  <div className="LandingDummyContainerInfoPlacesWithInfoPlace"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['1245'].slug[lang]}`}>{suggestedDestinations['1245'].name.es ? suggestedDestinations['1245'].name.es : suggestedDestinations['1245'].name.en}</Link></div>
                  <div className="LandingDummyContainerInfoPlacesWithInfoInfo">
                    <div style={{ marginRight: '8px' }}>
                      {suggestedDestinations['1245'].boats_count_with_child_places}
                                &nbsp;
barcos
                    </div>
                    <div>
                      {100}
                                &nbsp;
puertos
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="LandingDummyContainerImage"
                key="a"
                style={{
                  background: 'linear-gradient(0deg,rgba(0,0,0,0),rgba(0,0,0,0)), url("http://assets.boatjump.com/static/images/home/backgrounds/home-background-1.jpg")no-repeat center',
                  backgroundSize: 'cover',
                }}
              />
            </div>

            <div className="LandingLineContainerLine" />

            {/* Grecia */}

            <div className="LandingDummyContainer">
              <div className="LandingDummyContainerInfo">
                <div className="LandingDummyContainerInfoContainer">
                  <h2 style={{ marginBottom: '0%' }} className="LandingDummyContainerInfoContainerTitle"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['1149'].slug[lang]}`}>{suggestedDestinations['1149'].name.es ? suggestedDestinations['1149'].name.es : suggestedDestinations['1149'].name.en}</Link></h2>
                  {suggestedDestinations['1149'].name.es}
                </div>
                <div className="LandingDummyContainerInfoPlacesWithInfo">
                  <div className="LandingDummyContainerInfoPlacesWithInfoPlace"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['1719'].slug[lang]}`}>{suggestedDestinations['1719'].name.es ? suggestedDestinations['1719'].name.es : suggestedDestinations['1719'].name.en}</Link></div>
                  <div className="LandingDummyContainerInfoPlacesWithInfoInfo">
                    <div style={{ marginRight: '8px' }}>
                      {suggestedDestinations['1719'].boats_count_with_child_places}
                                &nbsp;
barcos
                    </div>
                    <div>
                      {100}
                                &nbsp;
puertos
                    </div>
                  </div>
                </div>
                <div className="LandingDummyContainerInfoPlacesWithInfo">
                  <div className="LandingDummyContainerInfoPlacesWithInfoPlace"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['1722'].slug[lang]}`}>{suggestedDestinations['1722'].name.es ? suggestedDestinations['1722'].name.es : suggestedDestinations['1722'].name.en}</Link></div>
                  <div className="LandingDummyContainerInfoPlacesWithInfoInfo">
                    <div style={{ marginRight: '8px' }}>
                      {suggestedDestinations['1722'].boats_count_with_child_places}
                                &nbsp;
barcos
                    </div>
                    <div>
                      {100}
                                &nbsp;
puertos
                    </div>
                  </div>
                </div>
                <div className="LandingDummyContainerInfoPlacesWithInfo">
                  <div className="LandingDummyContainerInfoPlacesWithInfoPlace"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['1724'].slug[lang]}`}>{suggestedDestinations['1724'].name.es ? suggestedDestinations['1724'].name.es : suggestedDestinations['1724'].name.en}</Link></div>
                  <div className="LandingDummyContainerInfoPlacesWithInfoInfo">
                    <div style={{ marginRight: '8px' }}>
                      {suggestedDestinations['1724'].boats_count_with_child_places}
                                &nbsp;
barcos
                    </div>
                    <div>
                      {100}
                                &nbsp;
puertos
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="LandingDummyContainerImage"
                key="a"
                style={{
                  background: 'linear-gradient(0deg,rgba(0,0,0,0),rgba(0,0,0,0)), url("http://assets.boatjump.com/static/images/home/backgrounds/home-background-1.jpg")no-repeat center',
                  backgroundSize: 'cover',
                }}
              />
            </div>

            <div className="LandingLineContainerLine" />

            {/* España */}

            <div className="LandingDummyContainer">
              <div className="LandingDummyContainerInfo">
                <div className="LandingDummyContainerInfoContainer">
                  <h2 style={{ marginBottom: '0%' }} className="LandingDummyContainerInfoContainerTitle"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['1'].slug[lang]}`}>{suggestedDestinations['1'].name.es ? suggestedDestinations['1'].name.es : suggestedDestinations['1'].name.en}</Link></h2>
                  {suggestedDestinations['1'].name.es}
                </div>
                <div className="LandingDummyContainerInfoPlacesWithInfo">
                  <div className="LandingDummyContainerInfoPlacesWithInfoPlace"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['116'].slug[lang]}`}>{suggestedDestinations['116'].name.es ? suggestedDestinations['116'].name.es : suggestedDestinations['116'].name.en}</Link></div>
                  <div className="LandingDummyContainerInfoPlacesWithInfoInfo">
                    <div style={{ marginRight: '8px' }}>
                      {suggestedDestinations['116'].boats_count_with_child_places}
                                &nbsp;
barcos
                    </div>
                    <div>
                      {100}
                                &nbsp;
puertos
                    </div>
                  </div>
                </div>
                <div className="LandingDummyContainerInfoPlacesWithInfo">
                  <div className="LandingDummyContainerInfoPlacesWithInfoPlace"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['1034'].slug[lang]}`}>{suggestedDestinations['1034'].name.es ? suggestedDestinations['1034'].name.es : suggestedDestinations['1034'].name.en}</Link></div>
                  <div className="LandingDummyContainerInfoPlacesWithInfoInfo">
                    <div style={{ marginRight: '8px' }}>
                      {suggestedDestinations['1034'].boats_count_with_child_places}
                                &nbsp;
barcos
                    </div>
                    <div>
                      {100}
                                &nbsp;
puertos
                    </div>
                  </div>
                </div>
                <div className="LandingDummyContainerInfoPlacesWithInfo">
                  <div className="LandingDummyContainerInfoPlacesWithInfoPlace"><Link to={`/${lang}/${slugPartBoatRent[lang]}/${suggestedDestinations['1511'].slug[lang]}`}>{suggestedDestinations['1511'].name.es ? suggestedDestinations['1511'].name.es : suggestedDestinations['1511'].name.en}</Link></div>
                  <div className="LandingDummyContainerInfoPlacesWithInfoInfo">
                    <div style={{ marginRight: '8px' }}>
                      {suggestedDestinations['1511'].boats_count_with_child_places}
                                &nbsp;
barcos
                    </div>
                    <div>
                      {100}
                                &nbsp;
puertos
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="LandingDummyContainerImage"
                key="a"
                style={{
                  background: 'linear-gradient(0deg,rgba(0,0,0,0),rgba(0,0,0,0)), url("http://assets.boatjump.com/static/images/home/backgrounds/home-background-1.jpg")no-repeat center',
                  backgroundSize: 'cover',
                }}
              />
            </div>
          </div>
          <div className="LandingStaticContentContainer" style={{ backgroundColor: 'white' }}>
            {/* {
            pageContext.styles.map(style=><link rel="stylesheet" href={style} />)
          } */}
            <div style={{ width: '100%', marginTop: '32px', maxWidth: '1440px' }} dangerouslySetInnerHTML={{ __html: customPost }} />
          </div>

        </div>
      </div>
    </>
  );
};

IndexPage.propTypes = {
  suggestedDestinations: PropTypes.array,
  customPost: PropTypes.string,
  styles: PropTypes.array,
};
IndexPage.defaultProps = {
  suggestedDestinations: null,
  customPost: '<div></div>',
  styles: [],
};

export default IndexPage;
